import {Messages} from "../constants";

export const coreFunctions = {
  main: () => {
    return true;
  },
  getToken: () => {
    return sessionStorage.getItem('auth_token') || '';
  },
  getCreds: () => {
    return sessionStorage.getItem('config_creds');
  },
  getPermission: (data: any) => {
    return JSON.parse(data);
  },
  calculatePermission: (data1: any, data2: any) => {
    const aa = data1;
    const bb = data2;
    return (aa & bb) == aa;
  },
  getRersourceName: (data: any) => {
    if (data && data.data && data.data.moduleNameCode) {
      return data.data.moduleNameCode;
    }
    return null;
  },
  getVerifyPermission: (data: any) => {
    if (data && data.data && data.data.verifyPermission) {
      return data.data.verifyPermission;
    }
    return null;
  },
  appConfig: () => {
    return {
      ripple: false,
      inputStyle: 'outlined',
      menuMode: 'static',
      colorScheme: 'light',
      theme: 'bootstrap4-light-purple',
      scale: 14,
    };
  },
  stateConfig: () => {
    return {
      staticMenuDesktopInactive: true,
      overlayMenuActive: false,
      profileSidebarVisible: false,
      configSidebarVisible: false,
      staticMenuMobileActive: false,
      menuHoverActive: false,
      geoLeftSidebarActive: false,
    };
  },
  validAttempt: (data: any) => {
    return {
      code: data.response,
    };
  },
  calculateRights: (data1: any, data2: any) => {
    let permission = data2.NONE;
    const { puedeCrear, puedeActualizar, puedeEliminar, puedeVer, esAdmin, esSuperAdmin } = data1;
    if (puedeCrear) {
      permission |= data2.CAN_CREATE;
    }
    if (puedeActualizar) {
      permission |= data2.CAN_UPDATE;
    }
    if (puedeEliminar) {
      permission |= data2.CAN_DELETE;
    }
    if (puedeVer) {
      permission |= data2.CAN_READ;
    }
    if (esAdmin) {
      permission |= data2.IS_ADMIN;
    }
    if (esSuperAdmin) {
      permission |= data2.IS_SUPER_ADMIN;
    }
    return permission;
  },
  buildForm: (data: any) => {
    const tmpBaseSchema = data.map((v: any) => {
      switch (v.id) {
        case 1:
          v.type = 'inputText';
          break;
        case 2:
          v.type = 'decimal';
          break;
        case 3:
          v.type = 'number';
          break;
        case 4:
          v.type = 'date';
          break;
        default:
          v.type = 'inputText';
          break;
      }
      v.label = '';
      return v;
    });
    return tmpBaseSchema;
  },
  parseRights: (data: any) => {
    let tmpPermission = data.toString(2).split('');
    const sizePermission = 7 - tmpPermission.length;
    if (sizePermission > 0) {
      const tmpCerosArray = Array.from({ length: sizePermission }, () => '0');
      tmpPermission = [...tmpCerosArray, ...tmpPermission];
    }
    return {
      CAN_READ: tmpPermission[5] == '1' ? true : false,
      CAN_UPDATE: tmpPermission[4] == '1' ? true : false,
      CAN_CREATE: tmpPermission[3] == '1' ? true : false,
      CAN_DELETE: tmpPermission[2] == '1' ? true : false,
      IS_ADMIN: tmpPermission[1] == '1' ? true : false,
      IS_SUPER_ADMIN: tmpPermission[0] == '1' ? true : false
    }
  },
  buildParam: (query: any) => {
    query.page = query.page ? query.page : 1;
    query.size = query.size ? query.size : 10;
    query.entityId = query.entityId ? query.entityId : null;
    query.idAmbito = query.idAmbito ? query.idAmbito : null;
    query.idSector = query.idSector ? query.idSector : null;
    query.idCategoria = query.idCategoria ? query.idCategoria : null;
    query.geographicalResourceId = query.geographicalResourceId ? query.geographicalResourceId : null;
    query.documentTypeId = query.documentTypeId ? query.documentTypeId : null;
    query.searchText = query.searchText ? query.searchText : null;
    query.statusDocument = query.statusDocument ? query.statusDocument : 'PENDING';
    query.format = query.format || '*';
    Object.keys(query).forEach(key => {
      if (query[key] === null) {
        delete query[key];
      }
    });
    let urlParam = '';
    const isObject = (value: any) => {
      return value !== null && typeof value === "object";
    }
    const tmpBuildParams = (paramIndex: any, dataKey: any, dataValue: any) => {
      let auxResp = '';
      if (paramIndex === 0) {
        if (isObject(dataValue)) {
          auxResp += `?${dataKey}=${JSON.stringify(dataValue)}`;
        } else {
          auxResp += `?${dataKey}=${dataValue}`;
        }
      } else if (isObject(dataValue)) {
        auxResp += `&${dataKey}=${JSON.stringify(dataValue)}`;
      } else {
        auxResp += `&${dataKey}=${dataValue}`;
      }
      return auxResp;
    }
    if (query && Object.keys(query).length) {
      let i = 0;
      for (const [k, v] of Object.entries(query)) {
        urlParam += tmpBuildParams(i, k, v);
        i += 1;
      }
    }
    return urlParam;
  },
  getGeneralError(condition: boolean = false, msg: string = '') {
      if (condition) return msg;
      return Messages.errors.general;
  }
};

export const formatPrice = (price: number): string => {
  return price.toLocaleString('en-US', { style: 'decimal' });
};
