import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AdminTemplateComponent } from './templates/admin-template/admin-template.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { NotfoundComponent } from './shared/pages/notfound/notfound.component';
import { SystemPermissionGuard } from './shared/guards/system-permission.guard';
import { Resource } from './shared/constants';
// verifyPermission comes from permissionTypes review it to set a value
const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule) },
  { path: 'error', loadChildren: () => import('./shared/pages/error/error.module').then((m) => m.ErrorModule) },
  { path: 'access', loadChildren: () => import('./shared/pages/access/access.module').then((m) => m.AccessModule) },
  {
    path: '',
    component: AdminTemplateComponent,
    canActivate: [AuthenticationGuard],
    canActivateChild: [SystemPermissionGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },

      {
        path: 'user',
        data: {
          moduleNameCode: Resource.USUARIOS,
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
      },
      //   {
      //     path: 'role',
      //     data: {
      //       moduleNameCode: 'ROLES',
      //       verifyPermission: 'canView',
      //     },
      //     loadChildren: () => import('./modules/role/role.module').then((m) => m.RoleModule),
      //   },
      //   {
      //     path: 'system-acl',
      //     data: {
      //       moduleNameCode: 'PERMISOS_SISTEMA',
      //       verifyPermission: 'canView',
      //     },
      //     loadChildren: () =>
      //       import('./modules/permiso-sistema/permiso-sistema.module').then((m) => m.PermisoSistemaModule),
      //   },
      {
        path: 'log-activity',
        data: {
          moduleNameCode: Resource.LOG_ACTIVIDADES,
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/log-actividad/log-actividad.module').then((m) => m.LogActividadModule),
      },
      {
        path: 'information-resource-type',
        data: {
          moduleNameCode: Resource.RECURSOS_INFORMACION,
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/information-resource-type/information-resource-type.module').then(
            (m) => m.InformationResourceTypeModule
          ),
      },
      {
        path: 'information-resource',
        data: {
          moduleNameCode: Resource.RECURSOS_INFORMACION,
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/information-resource/information-resource.module').then((m) => m.InformationResourceModule),
      },
      {
        path: 'web-map-service',
        data: {
          moduleNameCode: Resource.SERVICIOS_MAPAS_WEB,
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/web-map-service/web-map-service.module').then((m) => m.WebMapServiceModule),
      },
      {
        path: 'cartographic-resource',
        data: {
          moduleNameCode: Resource.RECURSOS_CARTOGRAFICOS,
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/cartographic-resource/cartographic-resource.module').then(
            (m) => m.CartographicResourceModule
          ),
      },
      {
        path: 'sld',
        data: {
          moduleNameCode: Resource.STYLES,
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/sld/sld.module').then((m) => m.SldModule),
      },
      {
        path: 'geo-process',
        data: {
          moduleNameCode: Resource.MODULE_GEOPROCESS,
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/geoprocessor/geoprocessor.module').then((m) => m.GeoprocessorModule),
      },
      {
        path: 'risk-maps',
        data: {
          moduleNameCode: Resource.MODULE_RISK_MAPS,
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/risk-maps/risk-maps.module').then((m) => m.RiskMapsModule),
      },
      {
        path: 'risk-maps/historical',
        data: {
          moduleNameCode: Resource.RECURSOS_CARTOGRAFICOS,
          verifyPermission: 'canView',
        },
        loadChildren: () =>
          import('./modules/cartographic-resource/cartographic-resource.module').then(
            (m) => m.CartographicResourceModule
          ),
      },
        {
            path: 'capacities',
            data: {
                moduleNameCode: Resource.MODULE_CAPACITIES,
                verifyPermission: 'canView',
            },
            loadChildren: () =>
                import('./modules/capacities/capacities.module').then(
                    (m) => m.CapacitiesModule
                ),
        },
    ],
  },
  {
    path: 'internal',
    canActivate: [AuthenticationGuard],
    canActivateChild: [SystemPermissionGuard],
    children: [
      {
        path: 'dashboards',
        data: {
          moduleNameCode: Resource.MODULE_DASHBOARD,
          verifyPermission: 'canView',
        },
        loadChildren: () => import('./modules/dashboards/dashboards.module').then((m) => m.DashboardsModule),
      },
    ],
  },
  {
    path: 'dashboards',
    loadChildren: () => import('./modules/dashboards/dashboards.module').then((m) => m.DashboardsModule),
  },
  { path: 'notfound', component: NotfoundComponent },
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
