export const Messages = Object.freeze({
    errors: {
        general: 'Se ha producido un error inesperado. Por favor, póngase en contacto con el soporte técnico o inténtelo nuevamente más tarde. Disculpe las molestias.',
        extracted: 'Se ha producido un error inesperado al intentar extraer los datos. Por favor, verifique el formato del archivo e inténtelo nuevamente.',
        created: 'El registro no fue creado. Por favor, corrija los datos e inténtelo nuevamente.',
        updated: 'El registro no fue actualizado. Por favor, corrija los datos e inténtelo nuevamente.',
        deleted: 'El registro no fue eliminado. Inténtelo más tarde.',
        invalid: 'Corrija los datos e inténtelo nuevamente',
        duplicated: 'El registro no se ha creado debido a que es duplicado. Por favor, revise los datos e intente nuevamente.'
    },
    confirm: '¿Estás seguro de eliminar este registro?',
    approveConfirm: '¿Estás seguro de aprobar este registro?',
    rejectConfirm: '¿Estás seguro de rechazar este registro?',
    created: 'El registro fue creado satisfactoriamente.',
    generated: 'El registro fue generado satisfactoriamente.',
    updated: 'El registro fue actualizado satisfactoriamente.',
    deleted: 'El registro fue eliminado satisfactoriamente.',
    uploaded: 'El recuros fue cargado correctamente',
    empty: 'No se han encontrado resultados.',
    queued: 'Esperando en cola....',
    processing: 'Procesando....',
});
