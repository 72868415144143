import {Component, ElementRef, OnDestroy, ViewChild, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subject, takeUntil} from 'rxjs';
import {CredentialsService} from 'src/app/shared/services';
import {LayoutService} from 'src/app/shared/services/app.layout.service';
import {ChangePasswordModalComponent} from '../change-password-modal/change-password-modal.component';
import {User} from '../../modules/user/interfaces';
import {GenericFunctions} from '../../shared/services/generic-functions';

@Component({
    selector: 'app-top-bar-template',
    templateUrl: './top-bar-template.component.html',
    styleUrls: ['./top-bar-template.component.scss'],
})
export class TopBarTemplateComponent implements OnInit, OnDestroy {
    @Input() showMenuButton = true;
    @Input() menuOpened: boolean = false;
    @Output() onToggleMenu = new EventEmitter<{ state: boolean }>();
    items!: MenuItem[];
    currentUser: User;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    private onDestroy$ = new Subject();
    public ref!: DynamicDialogRef;
    private confModal: DynamicDialogConfig<any> = {
        header: 'Cambiar Contraseña',
        width: '50%',
        contentStyle: {
            overflow: 'auto',
        },
        baseZIndex: 10000,
        closable: true,
    };
    isPublicDash: boolean = false;
    isDashboards: boolean = false;
    constructor(
        public layoutService: LayoutService,
        public router: Router,
        private route: ActivatedRoute,
        public credentialsService: CredentialsService,
        public dialogService: DialogService
    ) {
        this.currentUser = this.credentialsService.getLoggedUser();
        this.items = [
            {
                label: 'Seguridad',
                items: [
                    {
                        label: 'Cambiar Contraseña',
                        icon: 'pi pi-pencil',
                        command: () => {
                            this.openModal();
                        },
                    },
                ],
            },
            {
                label: 'Sesion',
                items: [
                    {
                        label: 'Salir',
                        icon: 'pi pi-sign-out',
                        command: () => {
                            this.logout();
                        },
                    },
                ],
            },
        ];
    }

    ngOnInit() {
        if (this.router.url == '/dashboards') {
            this.isPublicDash = true;
        }

        if (this.router.url.includes('/dashboards')) {
            this.isDashboards = true;
        }
    }

    public openModal() {
        this.confModal.header = 'Cambiar Contraseña';
        this.confModal.data = undefined;
        this.ref = this.dialogService.open(ChangePasswordModalComponent, this.confModal);
        this.ref.onClose.pipe(takeUntil(this.onDestroy$)).subscribe((resp) => {
            if (resp) {
                this.logout();
            }
        });
    }

    public changeTheme(theme: string, colorScheme: string) {
        const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
        const newHref = themeLink.getAttribute('href')!.replace(this.layoutService.config.theme, theme);
        this.layoutService.config.colorScheme;
        this.replaceThemeLink(newHref, () => {
            this.layoutService.config.theme = theme;
            this.layoutService.config.colorScheme = colorScheme;
            this.layoutService.onConfigUpdate();
        });
    }

    public changeMenuType(value: string = 'static') {
        this.layoutService.config.menuMode = value;
    }

    public logout() {
        this.credentialsService.removeUser();
        this.router.navigate(['']);
        window.location.reload();
    }

    private replaceThemeLink(href: string, onComplete: Function) {
        const id = 'theme-css';
        const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
            onComplete();
        });
    }

    public getCurrentUserName() {
        return GenericFunctions.getUserFullName(this.currentUser, true);
    }

    get getCurrentRole() {
        return this.currentUser?.role?.descripcionRol;
    }

    onMenuToggle() {
        this.layoutService.onMenuToggle();
        this.menuOpened = !this.menuOpened;
        this.onToggleMenu.emit({state: this.menuOpened});
    }

    onGeoSidebarToggle() {
        this.layoutService.onGeoSidebarToggle();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }
}
