import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {BaseHttpService} from './index';
import {lastValueFrom} from "rxjs";
import {IVisitor} from "../interfaces/visitor.interface";
import { CookieService } from 'ngx-cookie-service';
import {v4 as uuidv4} from 'uuid';
import {HttpOptions} from "../types";

@Injectable({
    providedIn: 'root',
})
export class VisitorService extends BaseService<IVisitor> {
    constructor(
        private readonly httpService: BaseHttpService,
        private cookieService: CookieService,
    ) {
        super(httpService, 'visitors');
    }

    async checkVisit(internal: boolean = true): Promise<IVisitor> {
        return lastValueFrom(this._baseHttpService.post(`${this.namespace}/check-visit`, this.getVisitor(internal)));
    }

    async countVisits(options?: HttpOptions): Promise<number> {
        return lastValueFrom(this._baseHttpService.get(`${this.namespace}/count/visits`, options));
    }

    generateVisitorCookie() {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 20);
        const current = uuidv4();
        this.cookieService.set('visitor', current, date);
        return current;
    }

    getVisitor(internal: boolean) {
        const data: IVisitor = {
            visitorToken: '',
            visits: 0,
            internal,
        }
        let current = this.cookieService.get('visitor');
        if (current) {
            data.visitorToken = current;
            return data;
        }

        current = this.generateVisitorCookie();
        data.visitorToken = current;
        return  data;
    }
}
