export const Resource = Object.freeze({
    USUARIOS: 'USUARIOS',
    ROLES: 'ROLES',
    USUARIOS_ADMIN_MENU: 'USUARIOS_ADMIN_MENU',
    LOG_ACTIVIDADES: 'LOG_ACTIVIDADES',
    RECURSOS_INFORMACION: 'RECURSOS_INFORMACION',
    RECURSOS_CARTOGRAFICOS: 'RECURSOS_CARTOGRAFICOS',
    SERVICIOS_MAPAS_WEB: 'SERVICIOS_MAPAS_WEB',
    STYLES: 'STYLES',
    MODULE_GEOPROCESS: 'MODULE_GEOPROCESS',
    GEOPROCESS_VULNERABILITIES: 'GEOPROCESS_VULNERABILITIES',
    GEOPROCESS_DATA: 'GEOPROCESS_DATA',
    MODULE_RISK_MAPS: 'MODULE_RISK_MAPS',
    RISK_MAPS_MANAGEMENT: 'RISK_MAPS_MANAGEMENT',
    VGLOBAL_MAPS_MANAGEMENT: 'VGLOBAL_MAPS_MANAGEMENT',
    VTOTAL_MAPS_MANAGEMENT: 'VTOTAL_MAPS_MANAGEMENT',
    RISK_MAPS_CHECKING: 'RISK_MAPS_CHECKING',
    MODULE_INTEROPERABILITY: 'MODULE_INTEROPERABILITY',
    INTEROPERABILITY_WINDY: 'INTEROPERABILITY_WINDY',
    MODULE_DASHBOARD: 'MODULE_DASHBOARD',
    DASHBOARDS_MANAGEMENT: 'DASHBOARDS_MANAGEMENT',
    ATTACHMENTS: 'ATTACHMENTS',
    MODULE_CAPACITIES: 'MODULE_CAPACITIES',
    CAPACITIES_MANAGEMENT: 'CAPACITIES_MANAGEMENT',
    VULNERABILITY_INDEXES_MANAGEMENT: 'VULNERABILITY_INDEXES_MANAGEMENT',
});
export const Colors = {
    none: '#FFFFFF',
    lowest: '#A8E6A1',
    low: '#008000',
    middle: '#FFF176',
    high: '#FFA500',
    highest: '#FF0000',
    default: '#000000',
};

export const RasterColors = {
    none: '#feffef',
    lowest: '#d6f0b3',
    low: '#72c8bd',
    middle: '#2498c1',
    high: '#234da1',
    highest: '#f477b5',
    default: '#000000',
};
export const FileTypes = {
    PDF: 'application/pdf',
    EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    WORD: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    IMAGE: 'image/*',
}

export const VulImages = [
    { filename: '01_VulTotal.jpg', label: 'Vulnerabilidad Total' },
    { filename: '02_VulPoblacional.jpg', label: 'Vulnerabilidad Poblacional' },
    { filename: '03_VulGlobal.jpg', label: 'Vulnerabilidad Global por Sector' },
    { filename: '04_Sect_Educacion.jpg', label: 'Vulnerabilidad del Sector Educación' },
    { filename: '05_Sect_Salud.jpg', label: 'Vulnerabilidad del Sector Salud' },
    { filename: '06_Sect_Vivienda.jpg', label: 'Vulnerabilidad del Sector Vivienda' },
    { filename: '07_Sect_SerBasicos.jpg', label: 'Vulnerabilidad del Sector Servicios Básicos' },
    { filename: '08_Sect_MovTelecomRH.jpg', label: 'Vulnerabilidad del Sector Movilidad, Telecomunicaciones y Recursos Hídricos' },
    { filename: '09_Sect_DesEconomico.jpg', label: 'Vulnerabilidad del Sector Económico' },
    { filename: '10_VulGlobal_Tipo.jpg', label: 'Vulnerabilidad Global por Tipo' },
    { filename: '11_VTipo_Fisica.jpg', label: 'Vulnerabilidad Física' },
    { filename: '12_VTipo_SocioEco.jpg', label: 'Vulnerabilidad Socioeconómica' },
    { filename: '13_VTipo_Ambiental.jpg', label: 'Vulnerabilidad Ambiental' },
    { filename: '14_VTipo_Institucional.jpg', label: 'Vulnerabilidad Institucional' },
];
