import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseMenuComponent} from "../../../shared/components/base.menu.component";

@Component({
    selector: 'app-top-bar-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class TopBarMenuComponent  extends BaseMenuComponent implements OnChanges {

    @Input() isPublicDash: boolean = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isPublicDash']) {
            if(this.isPublicDash) this.loadPublicMenu();
            else this.loadPrivateMenu();
        }
    }
}
