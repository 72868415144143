<ul class="layout-topbar-menu flex flex-nowrap justify-content-start">
    <ng-container *ngFor="let item of model; let i = index;">
        <li app-menu-item *ngIf="!item.separator" [item]="item" [index]="i" [root]="true" appPermission
            [resource]="item?.resourceName"
            [enableActions]="item?.right"
            [isPublicView]="item?.isPublic"
            class="ml-3"
        >
        </li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
</ul>
