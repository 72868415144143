import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BaseMenuComponent} from "../../shared/components/base.menu.component";

@Component({
  selector: 'app-menu-template',
  templateUrl: './menu-template.component.html',
  styleUrls: ['./menu-template.component.scss'],
})
export class MenuTemplateComponent extends BaseMenuComponent implements OnChanges {
    @Input() isPublicDash: boolean = false;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isPublicDash']) {
            if(this.isPublicDash) this.loadPublicMenu();
            else this.loadPrivateMenu();
        }
    }
}
