import {Injectable} from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import {WebsocketEventType, WebsocketMessageType} from "../types";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    private socket: Socket;
    constructor() {
        const host = environment.host.replace('/api', '');
        this.socket = io(host, { path: '/api/socket.io' });
    }

    sendMessage(name: WebsocketMessageType, message: any){
        this.socket.emit(name, message);
    }

    getMessages(name: WebsocketMessageType) {
        let observable = new Observable<{ user: String, message: String }>(observer => {
            this.socket.on(name, (data: any) => {
                observer.next(data);
            });
            return () => { this.socket.disconnect(); };
        });
        return observable;
    }
}
