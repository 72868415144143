<div class="progress-spinner" *ngIf="enableLoadingSpinner">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="layout-wrapper" [ngClass]="containerClass">
    <app-top-bar-template></app-top-bar-template>
    <div class="layout-sidebar block md:hidden">
        <app-side-bar-template></app-side-bar-template>
    </div>
    <div class="layout-main-container mt-6">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <app-footer-template></app-footer-template>
    </div>
    <div class="layout-mask"></div>
</div>
