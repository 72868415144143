<div class="layout-topbar flex justify-content-start align-items-start">
    <a class="layout-topbar-logo" routerLink="/home">
        <div class="text-left flex justify-content-center align-items-center w-full">
            <img class="md:block" src="assets/img/resources/logo.png" alt="logo" />
        </div>
    </a>

    <div class="flex justify-content-between flex-grow-1 align-items-start relative">
        <button
            *ngIf="isDashboards"
            class="p-link layout-menu-button layout-topbar-button block mt-3 pt-2"
            (click)="onGeoSidebarToggle()"
        >
            <i class="material-symbols-outlined">menu</i>
        </button>
        <button
            #menubutton
            class="p-link layout-menu-button layout-topbar-button block md:hidden mt-3 pt-2"
            (click)="onMenuToggle()"
        >
            <i *ngIf="isDashboards" class="material-symbols-outlined">menu_open</i>
            <i *ngIf="!isDashboards" class="material-symbols-outlined">menu</i>
        </button>
        <div class="flex-grow-1">
            <div [ngClass]="{'text-center w-full': true, 'text-2xl': !showMenuButton, 'text-xl': showMenuButton}">
                <div class="hidden md:block w-full">
                    <app-top-bar-menu
                        [isPublicDash]="isPublicDash"
                    ></app-top-bar-menu>
                </div>
                <span class="block md:hidden mt-3 pt-2">SIGRAV</span>
            </div>
        </div>

        <div *ngIf="!isPublicDash" #topbarmenu class="rounded layout-topbar-menu hover:bg-black-alpha-10 hover:text-white p-1"
            [ngClass]="{ 'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible }" style="max-width: 200px;">
            <button class="p-link p-1" (click)="menu.toggle($event)" style="min-width: 150px;">
                <div
                    class="m-0 w-full flex gap-2 justify-content-end align-items-center layout-topbar-button hover:text-white">
                    <div class="layout-topbar-button m-0">
                        <i class="pi pi-user"></i>
                        <span>Profile</span>
                    </div>
                    <div>
                        <label>{{ getCurrentUserName() }}</label>
                        <p class="text-sm">{{ getCurrentRole }}</p>
                    </div>
                </div>
            </button>

            <p-menu #menu [appendTo]="'body'" [popup]="true" [model]="items"></p-menu>
        </div>
    </div>
</div>
