import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Resource} from "../constants";
import {LayoutService} from "../services/app.layout.service";
import {PermissionService} from "../services/permission.service";
@Component({
    selector: 'app-base-menu-abstract',
    template: '',
})
abstract class BaseMenuComponent implements OnInit {
    protected model: any[] = [];

    public constructor(
        public layoutService: LayoutService,
        public permissionService: PermissionService,
    ) {}

    ngOnInit(): void {}

    protected loadPrivateMenu() {
        const historicalMapMenu = environment.enableLoadHistoricalMaps
            ? {
                label: 'Mapas Históricos',
                icon: 'folder',
                resourceName: Resource.RECURSOS_CARTOGRAFICOS,
                right: 'isAdmin',
                isPublic: false,
                routerLink: ['/risk-maps/historical'],
            }
            : {};

        this.model = [
            {
                label: '',
                isPublic: true,
                items: [{ label: 'Inicio', icon: 'home', routerLink: ['/home'], isPublic: true }],
            },
            {
                label: 'Menú',
                isPublic: true,
                items: [
                    {
                        label: 'Usuarios',
                        icon: 'person',
                        resourceName: Resource.USUARIOS_ADMIN_MENU,
                        right: 'canView',
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Usuarios',
                                icon: 'groups',
                                resourceName: Resource.USUARIOS,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/user'],
                            },
                            {
                                label: 'Log de Actividad',
                                icon: 'browse_activity',
                                resourceName: Resource.LOG_ACTIVIDADES,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/log-activity'],
                            },
                        ],
                    },
                    {
                        label: 'Recursos de Información',
                        icon: 'info',
                        resourceName: Resource.RECURSOS_INFORMACION,
                        right: 'canView',
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Administración de Mapas',
                                icon: 'location_on',
                                resourceName: Resource.RECURSOS_CARTOGRAFICOS,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/cartographic-resource'],
                            },
                            {
                                label: 'Administración de Estilos',
                                icon: 'format_color_fill',
                                resourceName: Resource.STYLES,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/sld'],
                            },
                        ],
                    },
                    {
                        label: 'Mapas de Riesgo',
                        icon: 'map',
                        resourceName: Resource.MODULE_RISK_MAPS,
                        right: 'canView',
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Mapas de Vuln. Global',
                                icon: 'globe',
                                resourceName: Resource.VGLOBAL_MAPS_MANAGEMENT,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/risk-maps/vuln-global'],
                            },
                            {
                                label: 'Mapas de Vuln. Total',
                                icon: 'apps',
                                resourceName: Resource.VTOTAL_MAPS_MANAGEMENT,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/risk-maps/vuln-total'],
                            },
                            {
                                label: 'Mapas de Riesgo',
                                icon: 'emergency',
                                resourceName: Resource.RISK_MAPS_MANAGEMENT,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/risk-maps/risk-map'],
                            },
                            {
                                label: 'Revisión de Mapas',
                                icon: 'done_all',
                                resourceName: Resource.RISK_MAPS_CHECKING,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/risk-maps/check'],
                            },
                            { ...historicalMapMenu },
                        ],
                    },
                    {
                        label: 'Geo-Procesamiento',
                        icon: 'data_table',
                        resourceName: Resource.MODULE_GEOPROCESS,
                        right: 'canView',
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Vulnerabilidades por Sector',
                                icon: 'group_work',
                                resourceName: Resource.GEOPROCESS_VULNERABILITIES,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/geo-process/vulnerabilities-sector'],
                            },
                            {
                                label: 'Vulnerabilidades por Tipo',
                                icon: 'border_clear',
                                resourceName: Resource.GEOPROCESS_VULNERABILITIES,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/geo-process/vulnerabilities-type'],
                            },
                            {
                                label: 'Datos de Indicadores',
                                icon: 'database',
                                resourceName: Resource.GEOPROCESS_DATA,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/geo-process/data'],
                            },
                        ],
                    },
                    {
                        label: 'Indices de Vulnerabilidad',
                        icon: 'percent',
                        resourceName: Resource.MODULE_CAPACITIES,
                        right: 'canView',
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Capacidades',
                                icon: 'cases',
                                resourceName: Resource.CAPACITIES_MANAGEMENT,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/capacities'],
                            },
                            {
                                label: 'Indices',
                                icon: 'heap_snapshot_thumbnail',
                                resourceName: Resource.VULNERABILITY_INDEXES_MANAGEMENT,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/capacities/indexes'],
                            },
                        ],
                    },
                    {
                        label: 'Interopera-bilidad',
                        icon: 'language',
                        resourceName: Resource.MODULE_INTEROPERABILITY,
                        right: 'canView',
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Interoperabilidad',
                                icon: 'captive_portal',
                                resourceName: Resource.SERVICIOS_MAPAS_WEB,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/web-map-service'],
                            },
                        ],
                    },
                    {
                        label: 'Dashboards',
                        icon: 'preview',
                        resourceName: Resource.MODULE_DASHBOARD,
                        right: 'canView',
                        isPublic: false,
                        class: 'bg-primary',
                        items: [
                            {
                                label: 'Dashboard Interno',
                                icon: 'public_off',
                                resourceName: Resource.DASHBOARDS_MANAGEMENT,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/internal/dashboards'],
                            },
                            {
                                label: 'Dashboard externo',
                                icon: 'public',
                                // TODO change this
                                resourceName: Resource.DASHBOARDS_MANAGEMENT,
                                right: 'canView',
                                isPublic: false,
                                routerLink: ['/dashboards'],
                            },
                        ],
                    },
                ],
            },
        ];
    }

    protected loadPublicMenu() {
        this.model = [
            {
                label: '',
                isPublic: true,
                items: [{ label: 'Inicio', icon: 'home', routerLink: ['/home'], isPublic: true }],
            }
        ];
    }

}


export {BaseMenuComponent};
