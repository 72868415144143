// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { BASE_CONF } from './base';

export const environment = {
  ...BASE_CONF,
  production: false,
  host: 'https://sigrav.bits.bo/api',
  captchaKey: '6LeKC90mAAAAAM0I9k8GzUGBXWojbPR59smATKwO',
  captchaEnabled: false,
  //captchaKey: "6Ldqhz0aAAAAAPln22bmygUUqHKcE_SS8AI_Iofy",
  handsontableKey: 'non-commercial-and-evaluation',
  // urlGeoserver: 'http://localhost:8080/geoserver',
  urlGeoserver: 'https://geoserver.bits.bo/geoserver',
  workspaceGeoserver: 'sigrav',
  enableLoadHistoricalMaps: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
